import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { SearchButton } from './SearchButton';
import Authorization, { CheckAuthorization } from "./Base/Authorization";
import { BlankCard } from './Base/Common/CommonUIComponents';
import { SubscribeToCard } from './SubscribeToCard';
import { getAPI } from './Base/API';
import GoogleAnalytics from '../Utils/GoogleAnalytics';
import ErrorBoundary from '../Utils/ErrorBoundary';
import { BrowserVersionNotSupported, isBrowserVersionSupported } from './BrowserVersion';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        const pageStates = [
            0,  //Pending
            1,  //Active
            2   //Inactive
        ];

        this.state = {
            block: true,
            pageStates: pageStates,
            pageStatus: pageStates[0] //starts pending
        };
    }

    componentDidMount() {
        this.refreshUserData();

        this.setState({ isBrowserVersionSupported: isBrowserVersionSupported() });
    }

    refreshUserData = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                global.channelList = data.channel;
                global.userAllowedProperties = data.userAllowedProperties;
                global.defaultProperty = data.defaultProperty;
                global.propertyName = data.propertyName;

                if (CheckAuthorization("CardClient")) {
                    this.getHotelData(data.defaultProperty);
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/User/v1/User');
    }

    getHotelData = (defaultProperty) => {
        const { pageStates } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                global.license = data.response[0].license;
                global.modules = data.response[0].modules;
                global.active = data.response[0].active;
                global.codeGenerationType = data.response[0].codeGenerationType;
                global.shortId = data.response[0].modules.some(el => el === "ShortId");
            }
            this.setState({ block: false, error: errorMessage, pageStatus: global.active ? pageStates[1] : pageStates[2] });
        }, `/api/Voucher/Hotel/v1/Hotel/${defaultProperty}`);
    }

    render() {
        const { block, error, pageStatus } = this.state;

        return (
            this.state.isBrowserVersionSupported ?
                <ErrorBoundary>
                    <div className="bg-light">
                        <GoogleAnalytics />

                        <Authorization
                            perform="CardClient"
                            yes={() => (
                                <>
                                    {pageStatus === 0 ?
                                        <BlankCard block={block} error={error} />
                                        :
                                        pageStatus === 1 ?
                                            <>
                                                <NavMenu />
                                                <Container className="bg-light ">
                                                    {this.props.children}
                                                    <SearchButton />
                                                </Container>
                                            </>
                                            :
                                            <SubscribeToCard />
                                    }
                                </>
                            )}
                            no={() => (
                                <SubscribeToCard />
                            )} 
                        />

                    </div>

                </ErrorBoundary>
                :
                <BrowserVersionNotSupported />
        );
    }
}